import React from 'react'
import { graphql } from 'gatsby'
import { navigateTo } from 'gatsby-link'
import Img from "gatsby-image"

import Home from '../../assets/img/home.jpg'

import {
  Container,
  Col,
  Row,
} from 'reactstrap'

import {
  Button,
} from '../../components'

import SEO from '../../components/seo'

const schemaOrg = `{
  "@context": "http://schema.org/",
  "@type": "Service",
  "serviceType": "Services Irrigation",
  "provider": {
    "@type": "LocalBusiness",
    "name": "Irriglobe Irrigation",
    "address": {
      "@type": "PostalAddress",
      "addressLocality": "Montréal",
      "addressRegion": "QC",
      "streetAddress": "8220 Pascal-Gagnon"
    },
    "description": "Installation de système d'irrigation. Installation de système d'arrosage automatique. Vente et réparation. Irriglobe, le spécialiste en irrigation!",
    "telephone": "514-905-6000"
  },
  "areaServed": {
    "@type": "AdministrativeArea",
    "name": "Montreal"
  },
  "areaServed": {
    "@type": "AdministrativeArea",
    "name": "Laval"
  },
  "areaServed": {
    "@type": "AdministrativeArea",
    "name": "Rive-Nord"
  },
  "areaServed": {
    "@type": "AdministrativeArea",
    "name": "Laurentides"
  },
  "hasOfferCatalog": {
    "@type": "OfferCatalog",
    "name": "Services Irrigation",
    "itemListElement": [
      {
        "@type": "OfferCatalog",
        "name": "Types services irrigation",
        "itemListElement": [
          {
            "@type": "Offer",
            "itemOffered": {
              "@type": "Service",
              "name": "Irrigation résidentielle"
            }
          },
          {
            "@type": "Offer",
            "itemOffered": {
              "@type": "Service",
              "name": "Irrigation commerciale"
            }
          },
          {
            "@type": "Offer",
            "itemOffered": {
              "@type": "Service",
              "name": "Irrigation municipale"
            }
          },
          {
            "@type": "Offer",
            "itemOffered": {
              "@type": "Service",
              "name": "Irrigation Toîts vert"
            }
          },
          {
            "@type": "Offer",
            "itemOffered": {
              "@type": "Service",
              "name": "Entretien annuel"
            }
          }
        ]
      }
    ]
  }
}`

class ServicesIndex extends React.Component {
  render() {
    const seoData = {
      title: "Services en Irrigation et Arrosage Automatique",
      description:
        "Consultez la liste de nos serives en Irrigation et Arrosage Automatique",
      cover: Home,
      path: '/services',
      schemaOrg
    }

    const images = this.props.data.allImageSharp
    const IrrigationResidentielle = images.edges.find(e => e.node.fluid.originalName === 'irrigation-residentielle.jpg')?.node.fluid
    const IrrigationCommerciale = images.edges.find(e => e.node.fluid.originalName === 'irrigation-commerciale.jpg')?.node.fluid
    const IrrigationMunicipale = images.edges.find(e => e.node.fluid.originalName === 'irrigation-municipale.jpg')?.node.fluid
    const IrrigationToitsVert = images.edges.find(e => e.node.fluid.originalName === 'irrigation-toits-vert.jpg')?.node.fluid
    const AgricultureUrbaine = images.edges.find(e => e.node.fluid.originalName === 'agriculture-urbaine.jpg')?.node.fluid
    const IrrigationGoutteAGoutte = images.edges.find(e => e.node.fluid.originalName === 'irrigation-goutte-a-goutte.png')?.node.fluid
    const IrrigationEauDePluie = images.edges.find(e => e.node.fluid.originalName === 'irrigation-eau-de-pluie.jpg')?.node.fluid
    const EntretienAnnuel = images.edges.find(e => e.node.fluid.originalName === 'entretien-annuel.jpg')?.node.fluid

    return (
      <div>
        <SEO seoData={seoData} />

        <Container>

          <Row className="centered-container" style={{marginTop: 100, marginBottom: 50}}>
            <Col lg={12} md={12} sm={12} xs={12} className="text-center">
              <h1>Nos services</h1>
            </Col>
          </Row>

          <Row style={{marginBottom: 100}}>
            <Col lg={8} md={8} xs={12}>
              <h2>Irrigation Résidentielle</h2>
              <Img fluid={IrrigationResidentielle} alt="Irrigation Résidentielle" className="d-block d-sm-block d-md-none"/>
              <p>Quel que soit la marque de votre système d’irrigation, Irriglobe Inc possède les compétences pour le réparer. Notre unité mobile dispose d’un vaste inventaire de pièces afin de compléter le service à la première visite dans la majorité des situations.</p>
                <Button
                  color="primary"
                  size="md"
                  round
                  onClick={() => navigateTo('/services/irrigation-residentielle')}
                >
                  En savoir plus
                </Button>
            </Col>
            <Col md={4} className="d-none d-md-block d-xs-none">
              <Img fluid={IrrigationResidentielle} alt="Irrigation Résidentielle" />
            </Col>
          </Row>

          <Row style={{marginBottom: 100}}>
            <Col lg={4} md={4} xs={12} className="d-none d-md-block d-xs-none">
              <Img fluid={IrrigationCommerciale} alt="Irrigation Commerciale"/>
            </Col>
            <Col lg={8} md={8} xs={12}>
              <h2>Irrigation Commerciale</h2>
              <Img fluid={IrrigationCommerciale} alt="Irrigation Commerciale" className="d-block d-sm-block d-md-none"/>
              <p>L’image d’une entreprise est bien souvent la première évaluation de votre clientèle. Alors saisissez l’occasion de la recevoir par une pelouse verte et abondante avant même leur entrée dans vos bureaux. Même si votre bâtiment possède une architecture commune, il est facilement réalisable de lui offrir un look accueillant et entretenu par la présence d’une pelouse verdoyante et de quelques plantes luxuriantes entretenues par une irrigation adéquate de grade commercial.</p>
                <Button
                  color="primary"
                  size="md"
                  round
                  onClick={() => navigateTo('/services/irrigation-commerciale')}
                >
                  En savoir plus
                </Button>
            </Col>
          </Row>

          <Row style={{marginBottom: 100}}>
            <Col lg={8} md={8} xs={12}>
              <h2>Irrigation municipale</h2>
              <Img fluid={IrrigationMunicipale} alt="Irrigation municipale" className="d-block d-sm-block d-md-none"/>
              <p>L’arrosage automatique est la solution adaptée à la vie moderne. L’économie d’eau étant au cœur de nos préoccupations, l’arrosage automatique vous facilitera la vie tout en respectant la logique écologique. Arroser la pelouse ou le jardin aux heures les plus fraîches de la journée permet de réduire le gaspillage dû à l’évaporation.</p>
                <Button
                  color="primary"
                  size="md"
                  round
                  onClick={() => navigateTo('/services/irrigation-municipale')}
                >
                  En savoir plus
                </Button>
            </Col>
            <Col lg={4} md={4} xs={12} className="d-none d-md-block d-xs-none">
              <Img fluid={IrrigationMunicipale} alt="Irrigation municipale" />
            </Col>
          </Row>

          <Row style={{marginBottom: 100}}>
            <Col lg={4} md={4} xs={12} className="d-none d-md-block d-xs-none">
              <Img fluid={IrrigationToitsVert} alt="Irrigation Toîts Vert" />
            </Col>
            <Col lg={8} md={8} xs={12}>
              <h2>Toîts Vert</h2>
              <Img fluid={IrrigationToitsVert} alt="Irrigation Toîts Vert" className="d-block d-sm-block d-md-none" />
              <p>Le plus important pour savoir si votre toit vert à besoin d’irrigation, est la répartition des pluie sur l’année. Une répartition homogène est idéale.</p>
                <Button
                  color="primary"
                  size="md"
                  round
                  onClick={() => navigateTo('/services/irrigation-toits-vert')}
                >
                  En savoir plus
                </Button>
            </Col>
          </Row>

          <Row style={{marginBottom: 100}}>
            <Col lg={8} md={8} xs={12}>
              <h2>Agriculture Urbaine</h2>
              <Img fluid={AgricultureUrbaine} alt="Agriculture Urbaine" className="d-block d-sm-block d-md-none"/>
              <p>Nous avons conçu un système qui récupère l’eau de la déshumidification du supermarché et qui l’achemine ensuite vers des réservoirs de décantation qui sont contrôlés par un algorithme qui gère les cycles d’arrosage. Le système permet d’irriguer le potager, à même le toit du supermarché, d’une superficie de plus de 2 500 mètres carrés.</p>
                <Button
                  color="primary"
                  size="md"
                  round
                  onClick={() => navigateTo('/services/agriculture-urbaine')}
                >
                  En savoir plus
                </Button>
            </Col>
            <Col lg={4} md={4} xs={12} className="d-none d-md-block d-xs-none">
              <Img fluid={AgricultureUrbaine} alt="Agriculture Urbaine" />
            </Col>
          </Row>

          <Row style={{marginBottom: 100}}>
            <Col lg={4} md={4} xs={12} className="d-none d-md-block d-xs-none">
              <Img fluid={IrrigationGoutteAGoutte} alt="Irrigation Goutte à Goutte" />
            </Col>
            <Col lg={8} md={8} xs={12}>
              <h2>Irrigation goutte à goutte</h2>
              <Img fluid={IrrigationGoutteAGoutte} alt="Irrigation Goutte à Goutte" className="d-block d-sm-block d-md-none" />
              <p>Un système d'irrigation par goutte à goutte est en réalité une technique d’arrosage par micro-irrigation, souvent utilisée dans les cultures agricoles situées dans des régions arides où les restrictions en eau sont importantes.</p>
                <Button
                  color="primary"
                  size="md"
                  round
                  onClick={() => navigateTo('/services/irrigation-goutte-a-goutte')}
                >
                  En savoir plus
                </Button>
            </Col>
          </Row>

          <Row style={{marginBottom: 100}}>
            <Col lg={8} md={8} xs={12}>
              <h2>Récupération eau de pluie</h2>
              <Img fluid={IrrigationEauDePluie} alt="Récupération eau de pluie" className="d-block d-sm-block d-md-none" />
              <p>Les systèmes de récupération d’eau de pluie sont la solution tout indiquée pour récolter et entreposer l’eau de pluie pour une panoplie d’utilisations, et ce que ce soit pour une application résidentielle ou un projet commercial d’envergure.</p>
                <Button
                  color="primary"
                  size="md"
                  round
                  onClick={() => navigateTo('/services/recuperation-eau-de-pluie')}
                >
                  En savoir plus
                </Button>
            </Col>
            <Col lg={4} md={4} xs={12} className="d-none d-md-block d-xs-none">
              <Img fluid={IrrigationEauDePluie} alt="Récupération eau de pluie" />
            </Col>
          </Row>

          <Row style={{marginBottom: 100}}>
            <Col lg={4} md={4} xs={12} className="d-none d-md-block d-xs-none">
              <Img fluid={EntretienAnnuel} alt="Entretien annuel" />
            </Col>
            <Col lg={8} md={8} xs={12}>
              <h2>Entretien annuel</h2>
              <Img fluid={EntretienAnnuel} alt="Entretien annuel" className="d-block d-sm-block d-md-none" />
              <p>Irriglobe offre un service complet d'entretien annuel.</p>
                <Button
                  color="primary"
                  size="md"
                  round
                  onClick={() => navigateTo('/services/entretien-annuel')}
                >
                  En savoir plus
                </Button>
            </Col>
          </Row>

        </Container>
      </div>
    )
  }
}

export default ServicesIndex

export const homePageQuery = graphql`
  query servicesQuery {
    site {
      siteMetadata {
        title
        description
        url: siteUrl
        author
      }
    }
    allImageSharp(filter: {fluid: {originalName: {in: [
      "irrigation-residentielle.jpg",
      "irrigation-commerciale.jpg"
      "irrigation-municipale.jpg",
      "irrigation-toits-vert.jpg",
      "agriculture-urbaine.jpg", 
      "irrigation-goutte-a-goutte.png",
      "irrigation-eau-de-pluie.jpg",
      "entretien-annuel.jpg"
    ]}}}) {
      edges {
        node {
          fluid(maxWidth: 400) {
            originalName
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  }
`
